<template>
  <div class="user-cabinet__inner">
    <div class="members__area">
      <div class="locked">
        <div class="locked__head">
          <div class="locked__head-price">
            <h3>Request group</h3>
          </div>
        </div>
        <div class="locked__body">
          <div class="locked__text">
            <h4>{{group.name}}</h4>
            <p>Group Type: {{group.type}}</p>
            <p>Category: {{group.category_id}}</p>
            <p v-if="group.type !== 'Open'">Cost, &euro;: {{group.cost}}</p>
            <p>{{group.description}}</p>
          </div>
          <hr>
          <a
            href="#"
            v-if="group.role === 'request'"
            class="locked__btn"
            @click.prevent="onShowModal"
          >
            Undo Request
          </a>
          <a
            href="#"
            v-else-if="group.role === 'guest'"
            class="locked__btn"
            @click.prevent="onSendRequest"
          >
            Request Membership
          </a>
        </div>
      </div>
      <div class="locked-message">
        <h3>Do you have any  questions?</h3>
        Contact: <a href="mailto:info@wethink.eu">info@wethink.eu</a>
      </div>
    </div>
    <Modal
      @close="hideModal(modalUndoRequest)"
      :id=modalUndoRequest
      title=""
      buttonTextOk="Undo"
      buttonVariantOk="danger"
      :showButtonCancel="true"
      @cancel="hideModal(modalUndoRequest)"
      @ok="onUndoRequest"
    >
      <p>Are you sure?</p>
    </Modal>
  </div>
</template>
<script>
import GroupService from '@/services/GroupService';
import Modal from '@/components/Modal.vue';

import me from '@/mixins/me';
import modal from '@/mixins/modal';

export default {
  name: 'GroupFeedRequest',
  mixins: [me, modal],
  components: { Modal },
  props: {
    group: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      modalUndoRequest: 'modalUndoRequest',
    };
  },
  created() {},
  methods: {
    onShowModal() {
      this.showModal(this.modalUndoRequest);
    },
    async onUndoRequest() {
      try {
        await GroupService.actionUser(this.group.id, { action: 'undo' });
        this.$router.push({ name: 'profileGroups' });
      } catch (e) {
        console.log({ textArg: e, variantArg: 'danger' });
      }
    },
    async onSendRequest() {
      try {
        const res = await GroupService.actionUser(this.group.id, { action: 'request' });

        if (res.data.status === 200) {
          const alert = {
            text: res.data.message,
            variant: 'success',
          };

          this.$router.push({ name: 'PrivateGroupsPage', params: { alert } });
        }
      } catch (e) {
        console.log({ textArg: e, variantArg: 'danger' });
      }
    },
  },
};
</script>
