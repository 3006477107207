import GroupService from '@/services/GroupService';

export default {
  data() {
    return {
      group: {
        admin: false,
        name: '',
        description: '',
        cnt_user: 0,
        cnt_project: 0,
        users: [],
      },
    };
  },
  async created() {
    await this.setGroup();
    this.setCountUsers();
    this.setUsers();
    this.setIsAdmin();
  },
  methods: {
    async getGroup(id) {
      let res = null;

      try {
        res = await GroupService.group(id);
        return res.data;
      } catch (e) {
        if (e.response.status === 404) {
          this.$router.push({ name: 404 });
        }

        return null;
      }
    },
    async setGroup() {
      const groupData = await this.getGroup(this.$route.params.id);
      this.group = groupData || this.group;
    },
    setCountUsers() {
      this.$emit('changeCountUsers', this.group.cnt_user);
    },
    setUsers() {
      const users = this.group.cnt_user > 5
        ? this.group.users.slice(0, 5)
        : this.group.users.slice(0);
      this.$emit('changeUsers', users);
    },
    setIsAdmin() {
      this.$emit('setGroup', this.group);
    },
  },
};
