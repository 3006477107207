<template>
  <div>
    <PostsContainer
      :channel-id="channelId"
      :posts="posts"
      :headers="headers"
      :showBtnAddPost="group.role === 'member' || group.role === 'admin'"
      :authUserRole="group.role"
      :isFilter="true"
      :unreadFeeds="unreadFeeds"
      @showFilter="$emit('showFilter')"
      @getPosts="getPosts"
      @deletePost="onDeletePost"
      @changePostParams="onChangePostParams"
    />
  </div>
</template>
<script>
import PostsContainer from '@/components/PostsContainer.vue';

import postGroupMixin from '@/mixins/posts/postGroupMixin';

export default {
  name: 'GroupFeedActive_V2',
  mixins: [postGroupMixin],
  components: { PostsContainer },
  props: {
    group: {
      type: Object,
      required: true,
    },
    unreadFeeds: {
      type: [Array, Object],
    },
  },
  data() {
    return {
      channelId: '',
    };
  },
  async created() {
    this.channelId = this.group.channel;
    this.$eventBus.$on('loadedGroup', this.getPosts);
    this.$eventBus.$on('addedFeed', this.addFeed);
  },
  methods: {
    addFeed(feed) {
      this.feeds.unshift(feed);

      if (this.feeds.length > 10) {
        this.feeds.pop();
      }
    },
    onDeletePost(postID) {
      this.posts = this.posts.filter((fd) => fd.id !== postID);
    },
  },
  beforeDestroy() {
    this.$eventBus.$off('loadedGroup', this.getPosts);
    this.$eventBus.$off('addedFeed', this.addFeed);
  },
};
</script>
<style lang="scss" scoped>
</style>
