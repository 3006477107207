<template>
  <div class="container">
    <GroupRequest v-if="group.type === 'Private'
      && (group.role === 'guest' || group.role === 'request')"
      :group="group"
    />
     <GroupHidden v-if="group.role === 'hidden'" :group="group" />
     <GroupActive v-else-if="group.id" :group="group" :unreadFeeds="unreadFeeds"/>
  </div>
</template>
<script>
import GroupActive from '@/views/profile/MyGroups/Group/Components/Feed/V2/GroupActive.vue';
import GroupRequest from '@/views/profile/MyGroups/Group/Components/Feed/GroupRequest.vue';
import GroupHidden from '@/views/profile/MyGroups/Group/Components/Feed/GroupHidden.vue';

import group from '@/mixins/group/group';
import content from '@/mixins/content';
import websocket from '@/mixins/websocket';
import me from '@/mixins/me';

export default {
  name: 'ProfileGroupFeed',
  mixins: [group, content, websocket, me],
  components: { GroupActive, GroupRequest, GroupHidden },
  data() {
    return {};
  },
  created() {},
  computed: {
    unreadFeeds() {
      return this.$store.getters.getUnreadGroupFeeds || [];
    },
  },
  methods: {
    setUnreadGroupFeed(event) {
      const feeds = this.$store.getters.getUnreadGroupFeeds || [];
      const data = JSON.parse(event.data).message;
      const newFeed = JSON.parse(event.data).update;
      this.$store.commit('SET_UNREAD_GROUP_FEEDS', [...feeds, ...data.feed]);
      this.$eventBus.$emit('setFeedsCount', Object.keys(data.feed).length, 'feeds');

      this.$eventBus.$emit('loadedGroup');

      if (newFeed) {
        this.$eventBus.$emit('addedFeed', newFeed);
      }
    },
  },
  watch: {
    group() {
      this.subscribeToChannel('group-feed', this.setUnreadGroupFeed, this.group.id);
    },
  },
  beforeDestroy() {
    this.$store.commit('RESET_UNREAD_GROUP_FEEDS');
  },
};
</script>
