import GroupService from '@/services/GroupService';

import exception from '@/mixins/exception';

export default {
  mixins: [exception],
  data() {
    return {
      posts: [],
      postParams: {
        page: 1,
        'per-page': 10,
      },
      totalPages: 1,
      headers: {},
      isLoadPageFirstly: false,
      isPosts: false,
    };
  },
  created() {
    this.getPosts().then((posts) => {
      this.isPosts = !!posts.length;
    });

    window.addEventListener('scroll', async () => {
      const {
        scrollTop,
        scrollHeight,
        clientHeight,
      } = document.documentElement;
      const footer = document.getElementsByClassName('footer');
      if (window.innerWidth <= 768
        && scrollTop + clientHeight >= scrollHeight - footer[0].scrollHeight) {
        await this.getPosts();
      }
      if (window.innerWidth > 768 && scrollTop + clientHeight >= scrollHeight) {
        await this.getPosts();
      }
    });

    // this.isLoadPageFirstly = true;
  },
  beforeDestroy() {
    window.removeEventListener('scroll', async () => {
      const {
        scrollTop,
        scrollHeight,
        clientHeight,
      } = document.documentElement;
      const footer = document.getElementsByClassName('footer');
      if (window.innerWidth <= 768
        && scrollTop + clientHeight >= scrollHeight - footer[0].scrollHeight) {
        await this.getPosts();
      }
      if (window.innerWidth > 768 && scrollTop + clientHeight >= scrollHeight) {
        await this.getPosts();
      }
    });
  },

  methods: {
    async getPosts() {
      if (this.postParams.page <= this.totalPages) {
        try {
          const res = await GroupService.getFeeds(this.$route.params.id, this.postParams);
          this.posts.push(...res.data);
          this.totalPages = res.headers['x-pagination-page-count'];
          this.postParams.page = +res.headers['x-pagination-current-page'] + 1;
        } catch (e) {
          console.log({ textArg: e, variantArg: 'danger' });
          // this.posts = [];
          this.handleException(e);
        }
      }

      return this.posts;
    },
    onDeletePost(postID) {
      this.posts = this.posts.filter((fd) => fd.id !== postID);
    },
    onChangePostParams(postParams) {
      this.posts = [];
      this.totalPages = 1;
      this.postParams = postParams;
    },
  },
};
